<template>
    <div class="home">
        <el-card class="box-card">
            <div class="text">
                单位名称：<span>{{ deptInfo.title }}</span>
            </div>
            <div class="text">地址：{{ deptInfo.address || '无' }}</div>
            <div class="text">
                管家卡账户：{{ deptInfo.bankCardno || '无' }}
            </div>
            <div class="text" v-show="deptInfo.bankName">
                开户行：{{ deptInfo.bankName || '无' }}
            </div>
            <div class="text" v-show="deptInfo.bankOwner">
                开户名称：{{ deptInfo.bankOwner || '无' }}
            </div>
            <div class="text">联系人：{{ deptInfo.contactName || '无' }}</div>
            <div class="text">
                联系人电话：{{ deptInfo.contactPhone || '无' }}
            </div>
            <div class="text">
                开启支付范围：
                <el-tag v-if="deptInfo.rangeSign">支持部分商家</el-tag>
                <!-- <el-button
                    type="warning"
                    size="small"
                    v-if="deptInfo.rangeSign"
                    @click="unitShopEdit"
                >
                    查看绑定商家
                </el-button> -->
                <el-tag v-else>支持所有商家</el-tag>
            </div>

            <el-button type="primary" size="small" @click="openEdit">
                编辑
            </el-button>
        </el-card>
        <!-- <el-card class="box-card"></el-card> -->
        <!--选择单位/商家-->
        <el-dialog
            title="选择商家"
            :visible.sync="unitShopDialog"
            width="600px"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
        >
            <div style="height: 500px; overflow-y: scroll">
                <el-input
                    placeholder="输入关键字进行过滤"
                    v-model="unitShopFilterText"
                >
                </el-input>
                <el-tree
                    style="margin: 20px 0 0 0"
                    show-checkbox
                    highlight-current
                    default-expand-all
                    node-key="id"
                    ref="unitShopTree"
                    :data="unitShopList"
                    :default-checked-keys="unitShopFields.ids"
                    :props="unitShopDefaultProps"
                    :filter-node-method="unitShopFilterNode"
                >
                </el-tree>
                <div style="margin: 20px 0 0 0; text-align: center">
                    <el-button
                        type="primary"
                        size="medium"
                        @click="unitShopSubmit"
                    >
                        提交
                    </el-button>
                </div>
            </div>
        </el-dialog>
        <!--编辑-->
        <el-dialog
            title="编辑单位信息"
            :visible.sync="myFormDialog"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
            width="600px"
        >
            <el-form
                :model="myFormFields"
                :rules="myFormRules"
                ref="myForm"
                label-width="90px"
            >
                <el-form-item label="单位名称" prop="title">
                    <el-input disabled v-model="myFormFields.title"></el-input>
                </el-form-item>
                <el-form-item label="地址" prop="address">
                    <el-input v-model="myFormFields.address"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="contactName">
                    <el-input v-model="myFormFields.contactName"></el-input>
                </el-form-item>
                <el-form-item label="联系人电话" prop="contactPhone">
                    <el-input v-model="myFormFields.contactPhone"></el-input>
                </el-form-item>
                <!-- <el-form-item label="支付范围" prop="rangeSign">
                    <el-switch
                        v-model="myFormFields.rangeSign"
                        active-text="部分商家"
                        :active-value="1"
                        inactive-text="全部商家"
                        :inactive-value="0"
                    >
                    </el-switch>
                </el-form-item> -->

                <el-form-item style="margin: 0">
                    <el-button type="primary" @click="myFormSubmit">
                        提交
                    </el-button>
                    <el-button @click="myFormReset">重置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            userInfo: null,
            deptInfo: {},
            allowFields: {},
            unitShopDialog: false,
            unitShopFilterText: '',
            unitShopFields: {
                ids: [],
            },
            unitShopList: [],
            unitShopDefaultProps: {
                children: 'children',
                label: 'name',
            },
            myFormDialog: false,
            myFormFields: {},
            myFormRules: {
                name: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                ],
                // contactPhone: [
                //     {
                //         type: 'number',
                //         message: '手机号格式不正确',
                //         trigger: 'blur',
                //         transform(value) {
                //             if (!/^1\d{10}$/.test(value)) {
                //                 return false
                //             } else {
                //                 return Number(value)
                //             }
                //         },
                //     },
                // ],
            },
        }
    },
    created() {
        // this.userInfo = this.$store.state.user.userInfo
        this.getDeptInfo()
        this.getUnitShopList()
    },
    methods: {
        getDeptInfo() {
            this.$api.department.department_info().then((res) => {
                this.deptInfo = res
                this.myFormFields = Object.assign({}, res)
            })
        },
        //选择商家 - 拉取列表
        getUnitShopList() {
            this.$api.store_select().then((res) => {
                this.unitShopList = res
            })
        },
        //选择单位/商家 - 编辑
        unitShopEdit() {
            this.$api
                .allowPay_list({
                    type: 1,
                    id: this.deptInfo.id,
                })
                .then((res) => {
                    this.unitShopFields.ids = res
                    this.unitShopDialog = true
                })
        },
        //选择单位/商家 - 关键字过滤
        unitShopFilterNode(value, data) {
            if (!value) return true
            return data.title.indexOf(value) !== -1
        },
        //选择单位/商家 - 提交
        unitShopSubmit() {
            this.unitShopFields.ids = this.$refs.unitShopTree.getCheckedKeys()
            this.$api
                .allowPay_bind({
                    type: 1,
                    id: this.deptInfo.id,
                    ids: this.unitShopFields.ids,
                })
                .then((res) => {
                    this.$message({
                        duration: 1000,
                        type: 'success',
                        message: '绑定成功' || res,
                    })
                    this.unitShopDialog = false
                })
        },
        openEdit() {
            this.myFormDialog = true
        },
        //新增/编辑 - 表单重置
        myFormReset() {
            this.$refs['myForm'].resetFields()
        },
        //新增/编辑 - 表单提交
        myFormSubmit() {
            this.$refs['myForm'].validate((valid) => {
                if (valid) {
                    this.$api.department
                        .department_update(this.myFormFields)
                        .then((res) => {
                            this.$message({
                                duration: 1000,
                                type: 'success',
                                message: res,
                            })
                            this.myFormDialog = false
                            this.getDeptInfo()
                        })
                }
            })
        },
    },
}
</script>

<style>
.box-card {
    width: 500px;
}
.text {
    line-height: 1.5rem;
    font-size: 14px;
    margin-bottom: 10px;
    color: #666;
}
</style>
